import { BetaBanner } from "components/common/BetaBanner/BetaBanner";
import { AdBottomLeaderboard } from "components/modules/Ads/AdBottomLeaderboard";
import { AdLeftSidebar } from "components/modules/Ads/AdLeftSidebar";
import { AdRightSidebar } from "components/modules/Ads/AdRightSidebar";
import { AdTopLeaderboard } from "components/modules/Ads/AdTopLeaderboard";
import type { ReactNode } from "react";
import { Footer, type LanguageProps } from "../Footer/Footer";
import { Tools } from "../Tools/Tools";

export interface UploadPageLayoutProps {
    title: string | ReactNode;
    subTitle: string | ReactNode;
    content: ReactNode;
    uploader: ReactNode;
    tutorial: ReactNode;
    navbar: ReactNode;
    pageUrl: LanguageProps["url"];
    preToolsSlot?: ReactNode;
}

export const UploadPageLayout = ({
    title,
    subTitle,
    content,
    uploader,
    navbar,
    tutorial,
    preToolsSlot,
    pageUrl,
}: UploadPageLayoutProps) => {
    return (
        <div className="max-w-1440 page-shadow dark:shadow-black dark:bg-darkSurface-100">
            {navbar}
            <div className="mx-4 mt-8 -mb-8">
                <BetaBanner />
            </div>
            <div className="text-center">
                <h1
                    className="mt-16 text-3xl font-semibold light:text-black dark:text-white"
                    data-testid="upload-page-title"
                >
                    {title}
                </h1>
                <p
                    className="dark:text-darkSurface-600 text-xl md:text-22 pt-[10px]"
                    data-testid="upload-page-subtitle"
                >
                    {subTitle}
                </p>
            </div>

            <div className="mx-auto mt-5 w-[320px] md:w-[728px] h-[100px] md:h-[90px]">
                <AdTopLeaderboard />
            </div>

            <div className="flex gap-6 mx-6 mt-5">
                <div className="hidden lg:block lg:min-w-[160px] xl:min-w-[300px] w-[160px] lg:w-[300px] h-[600px]">
                    <AdLeftSidebar />
                </div>
                <div className="flex-grow">
                    {uploader}
                    <div className="flex justify-center">{content}</div>
                </div>
                <div className="hidden lg:block lg:min-w-[160px] xl:min-w-[300px] w-[160px] lg:w-[300px] h-[600px]">
                    <AdRightSidebar />
                </div>
            </div>

            <div className="mx-auto mt-20 w-[320px] md:w-[728px] h-[100px] md:h-[90px]">
                <AdBottomLeaderboard />
            </div>

            {tutorial}
            {preToolsSlot}
            <Tools />
            <Footer url={pageUrl} />
        </div>
    );
};
